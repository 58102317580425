@media only screen and (max-width: 1019px) {
  .header-main {
    min-width: 375px; } }

@media only screen and (min-width: 1020px) {
  .header-main {
    min-width: 985px; } }

@media only screen and (max-width: 1019px) {
  .header-main .holder {
    height: 64px;
    padding-top: 14px;
    text-align: center; } }

@media only screen and (min-width: 1020px) {
  .header-main .holder {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media only screen and (min-width: 1020px) {
  .header-main .header-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; } }

@media only screen and (max-width: 1019px) {
  .header-main .logo-link {
    display: inline-block; } }

@media only screen and (min-width: 1020px) {
  .header-main .logo-link {
    display: block; } }

@media only screen and (min-width: 1020px) {
  .header-main .logo-link.logo-link_1 {
    margin: 0 auto; } }

.header-main .logo-icon {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .header-main .logo-icon {
      width: 95px;
      height: 35px;
      margin: 0 auto; } }
  @media only screen and (min-width: 1020px) {
    .header-main .logo-icon {
      width: 138.4px;
      height: 51px; } }
  @media only screen and (max-width: 1019px) {
    .header-main .logo-icon.nerdibot {
      width: 136px;
      height: 45px; } }
  @media only screen and (min-width: 1020px) {
    .header-main .logo-icon.nerdibot {
      width: 151.1px;
      height: 50px; } }
  @media only screen and (max-width: 1019px) {
    .header-main .logo-icon.logo-au {
      width: 90px;
      height: 40px; } }
  @media only screen and (min-width: 1020px) {
    .header-main .logo-icon.logo-au {
      width: 90px;
      height: 40px; } }
  @media only screen and (max-width: 1019px) {
    .header-main .logo-icon.logo-us {
      width: 90px;
      height: 36px; } }
  @media only screen and (min-width: 1020px) {
    .header-main .logo-icon.logo-us {
      width: 90px;
      height: 36px; } }

.header-main .logo-icon_1 {
  width: 95px; }

.header-main .logo-icon_light {
  fill: #fff; }

.header-main .logo-ca-icon-light {
  display: block;
  width: 96px;
  height: 45px; }

@media only screen and (max-width: 1019px) {
  .header-main .hero__go-home {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .header-main .hero__go-home {
    margin-left: auto;
    /*      position: absolute;
      top: 32px;
      right: 128px;*/ } }

@media only screen and (max-width: 1019px) {
  .header-main .input.input-select__countries {
    position: absolute;
    top: 14px;
    right: 20px; } }

@media only screen and (min-width: 1020px) {
  .header-main .input.input-select__countries {
    margin-left: 60px;
    /*
      position: absolute;
      top: 32px;
      right: 0;
      */ } }

.headerClp3 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

.headerClp4 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (max-width: 1019px) {
    .headerClp4 .holder {
      height: 87px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0; } }
  @media only screen and (max-width: 1019px) {
    .headerClp4 .logo-icon.logo-us {
      width: 101px;
      height: 39px; } }
  @media only screen and (min-width: 1020px) {
    .headerClp4 .logo-icon.logo-us {
      width: 114px;
      height: 45px; } }
  @media only screen and (max-width: 1019px) {
    .headerClp4 .hnav-mob .open-btn {
      position: absolute;
      top: 36px;
      right: 20px;
      left: auto; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
.header_1 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

.header_2 {
  background-color: #fff; }
  @media only screen and (max-width: 1019px) {
    .header_2 {
      box-shadow: inset 0 -1px 0 0 #d5d6de; } }
  @media only screen and (min-width: 1020px) {
    .header_2 {
      box-shadow: inset 0 -1px 0 0 #e4e5eb; } }

.header_3 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (max-width: 1019px) {
    .header_3 .holder {
      padding-top: 25px; } }
  @media only screen and (max-width: 1019px) {
    .header_3 .logo-icon {
      width: 122px;
      height: 45px; } }

.header_4 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (min-width: 1020px) {
    .header_4 .holder {
      justify-content: center; } }
  @media only screen and (max-width: 1019px) {
    .header_4 .logo-icon {
      width: 122px;
      height: 45px; } }

.header_5 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  @media only screen and (max-width: 1019px) {
    .header_5 .holder {
      padding-top: 35px; } }
  @media only screen and (max-width: 1019px) {
    .header_5 .logo-icon {
      width: 107px;
      height: 39px; } }
  .header_5 .back-link {
    position: absolute; }
    @media only screen and (max-width: 1019px) {
      .header_5 .back-link {
        top: 21px;
        left: 20px; } }
    @media only screen and (min-width: 1020px) {
      .header_5 .back-link {
        top: 34px;
        left: 15px; } }

.header_blog {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; }
  @media only screen and (max-width: 1019px) {
    .header_blog {
      box-shadow: inset 0 -1px 0 0 #d5d6de; } }
  @media only screen and (min-width: 1020px) {
    .header_blog {
      box-shadow: inset 0 -1px 0 0 #e4e5eb; } }
  @media only screen and (max-width: 1019px) {
    .header_blog .holder {
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  @media only screen and (min-width: 1020px) {
    .header_blog .holder {
      padding-top: 0; } }
  @media only screen and (max-width: 1019px) {
    .header_blog .logo-icon.logo-blog {
      width: 154.8px;
      height: 35px; } }
  @media only screen and (min-width: 1020px) {
    .header_blog .logo-icon.logo-blog {
      width: 199px;
      height: 45px; } }

.header_ca .holder {
  height: 79px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1019px) {
    .header_ca .holder {
      justify-content: center; } }
  @media only screen and (min-width: 1020px) {
    .header_ca .holder {
      justify-content: space-between; } }

@media only screen and (min-width: 1020px) {
  .header_ca .header-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; } }

.header_ca .logo-link {
  display: block; }

.header_ca .logo-icon {
  display: block;
  width: 96px;
  height: 45px; }
  @media only screen and (max-width: 1019px) {
    .header_ca .logo-icon {
      margin: 0 auto; } }

.header_ca_1 {
  box-shadow: inset 0 -1px 0 0 #e4e5eb; }

.header_ca_2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

@media only screen and (max-width: 1019px) {
  .header_ca_3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; } }

@media only screen and (min-width: 1020px) {
  .header_ca_4 .holder {
    justify-content: center; } }

.logo-icon-ca {
  display: block; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
